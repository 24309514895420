import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import useScreenRecognition from 'hooks/useScreenRecognition';
import { IGatsbyImageComponentProps } from './models';
import './GatsbyImage.scss';
import './ShadowGatsbyImage.scss';

const GatsbyImage: FC<IGatsbyImageComponentProps> = ({
  isLazyLoading = true,
  alt = '',
  className = '',
  objectFit,
  objectPosition,
  image,
  useFocalPoint,
  ...restProps
}) => {
  const { isNotMobile } = useScreenRecognition();
  const padY = parseInt(image?.focalPoint?.y || '50', 10);
  const padX = parseInt(image?.focalPoint?.x || '50', 10);
  const svgRest: { [key: string]: any } = restProps;
  const isSvg = image?.svg?.content;
  const loading = isLazyLoading ? 'lazy' : 'eager';

  const imageToRender = isNotMobile
    ? image?.gatsbyImage
    : image?.mobileImage
    ? image?.mobileImage
    : image?.gatsbyImage;

  const imageProps = {
    className,
    alt,
    objectFit: objectFit || 'cover',
    objectPosition: useFocalPoint ? `${padX}% ${padY}%` : objectPosition || '50% 50%',
    ...restProps,
  };

  return (
    <>
      {isSvg ? (
        <DangerouslySetInnerHtml
          html={image.svg.content}
          element="div"
          className={`gatsby-svg-image ${className}`}
          {...svgRest}
        />
      ) : null}
      {!isSvg && imageToRender?.childImageSharp?.fixed ? (
        <Img {...imageProps} fixed={imageToRender.childImageSharp.fixed} loading={loading} />
      ) : null}
      {!isSvg && imageToRender?.childImageSharp?.fluid ? (
        <Img {...imageProps} fluid={imageToRender.childImageSharp.fluid} loading={loading} />
      ) : null}
    </>
  );
};

export const query = graphql`
  fragment FragmentSmallImage on File {
    childImageSharp {
      fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentMiddleImage on File {
    childImageSharp {
      fluid(maxWidth: 950) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentLargeImage on File {
    childImageSharp {
      fluid(maxWidth: 1920) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  fragment FragmentGatsbyProps on TGatsbyImageProperties {
    fallbackUrl
    url
    focalPoint {
      type
      x
      y
    }
    svg {
      content
    }
  }

  fragment FragmentGatsbyImage on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentLargeImage
    }
  }

  fragment FragmentGatsbyImageSm on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentSmallImage
    }
  }

  fragment FragmentGatsbyImageMd on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentMiddleImage
    }
  }

  fragment FragmentGatsbyImageLg on TGatsbyImageProperties {
    ...FragmentGatsbyProps
    gatsbyImage {
      ...FragmentLargeImage
    }
  }
`;

export default GatsbyImage;
