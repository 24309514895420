export const MOBILE_BREAKPOINT = 375;
export const TABLET_BREAKPOINT = 768;
export const DESKTOP_BREAKPOINT = 1024;
export const LAYOUT_WRAPPER = 1420;

export const MONTH_LONG = 'long';
export const DATE_TYPE = 'ordinal';

const HEADER_ID = 'header';
const FOOTER_ID = 'footer';
const CONTENT_ID = 'siteContent';

export const ELEMENTS = {
  HEADER_ID,
  CONTENT_ID,
  FOOTER_ID,
};
