import { ELEMENTS } from 'utils/constants';

export const isBrowser = () => typeof window !== 'undefined';

export const getLocationPath = () => (isBrowser() ? window.location.pathname : null);

export const getLocationQS = () => (isBrowser() ? window.location.search : null);

export const getLocationQueryStringParam = (
  param: string,
  strategy: 'string' | 'array' = 'array'
) => {
  if (!isBrowser()) {
    return strategy === 'string' ? '' : [];
  }
  const qs = new URLSearchParams(window.location.search);

  const stringValue = decodeURIComponent(qs.get(param) || '');
  if (strategy === 'string') {
    return stringValue;
  }

  try {
    return JSON.parse(`[${stringValue}]`);
  } catch (e) {
    console.error(e);

    return [];
  }
};

export const generateLocationQueryString = (param: string, value: string, pathname?: string) => {
  if (!isBrowser()) {
    return '';
  }
  const qs = new URLSearchParams(window.location.search);
  qs.set(param, value);

  return `${pathname || getLocationPath()}?${qs.toString()}`;
};

export const prepareClassName = (name: string) => String(name).replace(/\s/g, '-').toLowerCase();

export const scrollToTarget = (
  el: string | HTMLElement | HTMLDivElement,
  offset: number = 0,
  withoutHeader: boolean = false
): void => {
  if (!isBrowser()) {
    return;
  }
  const element: HTMLElement | null = typeof el === 'string' ? document.getElementById(el) : el;
  const header: HTMLElement | null = document.getElementById(ELEMENTS.HEADER_ID);
  const headerHeight: number = header ? header.clientHeight : 0;
  if (element) {
    const bodyRect: number = document.body.getBoundingClientRect().top;
    const elementRect: number = element.getBoundingClientRect().top;

    const elementPosition: number = elementRect - bodyRect;
    const offsetPosition: number = elementPosition - (withoutHeader ? 0 : headerHeight);

    window.scrollTo({
      top: offsetPosition - offset,
      behavior: 'smooth',
    });
  }
};

export const getDOMRectById = (id: string): DOMRect | undefined =>
  isBrowser() ? document.getElementById(id)?.getBoundingClientRect() : undefined;

export const generateUrlParamsArray = (arrData: string[]): string =>
  encodeURIComponent(JSON.stringify(arrData));

export const getRemovedParamFromLocationQueryString = (param: string): string | null => {
  if (!isBrowser()) {
    return '';
  }
  const qs = new URLSearchParams(window.location.search);
  qs.delete(param);

  const result = qs.toString();
  if (!result) {
    return getLocationPath();
  }

  return `${getLocationPath()}?${qs.toString()}`;
};

export const setUrl = (url: string): void => {
  if (isBrowser()) {
    window.history.replaceState({}, '', url);
  }
};
